import { EntidadeBase } from '../EntidadeBase';
import { RoleEnum } from './roles';

export class Autorizacao extends EntidadeBase {
    id: number;
    nome: string;
    authority: RoleEnum;

    static toArray(jsons: any[]): Autorizacao[] { // TODO: Tentar mover esse tipo de método para a classe base
        const objetos: any[] = [];
        if (jsons != null) {
          for (const json of jsons) {
            objetos.push(new Autorizacao(json));
          }
        }
        return objetos;
      }
}

import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';

import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    LoginRoutingModule
  ],
  declarations: [
    LoginComponent
  ],
  providers: [ ]
})
export class LoginModule {}

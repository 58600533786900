import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './auth-guard.service';
import { Modal3Module } from './../modal3/modal3.module';
import { Modal3Component } from './../modal3/modal3.component';

@NgModule({
  imports: [
    Modal3Module
  ],
  providers: [
    AuthService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  entryComponents: [
    Modal3Component
  ]
})
export class AuthModule {}
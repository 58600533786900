import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Uteis } from '../../shared/Uteis';

@Component({
  selector: 'az-error',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './error.component.html'
})

export class ErrorComponent implements OnInit {
    router: Router;
    
    constructor(router: Router) {
        this.router = router;
    }

    searchResult(): void {
        this.router.navigate(['pages/search']);
    }

    ngOnInit() {
        Uteis.esconderOverlay();
    }
}
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { ErrorComponent } from './pages/error/error.component';
import { AuthGuard } from './shared/authentication/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { GovBrComponent } from './gov-br/gov-br.component';
import { Tela1Component } from './tela1/tela1.component';

export const routes: Routes = [
  { path: '', redirectTo: 'dlogin', pathMatch: 'full' },
  { path: 'dlogin', component: Tela1Component },
  { path: 'login', component: LoginComponent },
  { path: 'govbr', component: GovBrComponent },
  { path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: '**', component: ErrorComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
});
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from "rxjs/operators";

import { environment } from '../../environments/environment';
import { AuthService } from '../shared/authentication/auth.service';
import { Uteis } from '../shared/Uteis';

const clientId = environment.clientId;
const redirectUri = environment.redirectUri;
const urlProvider = environment.urlProvider;

@Component({
  selector: 'az-gov-br',
  templateUrl: './gov-br.component.html',
  styleUrls: ['./gov-br.component.scss']
})
export class GovBrComponent implements OnInit {

  public backend_jwt = {};
  public access_token = '';
  public mostrar_foto = false;
  public empresas = [];
  public api_data = { principal: null };
  token: String;
  erroTOP: any;

  constructor(
    private activatedRoute: ActivatedRoute, private http: HttpClient, private auth: AuthService) {
      Uteis.mostrarOverlay();
  }

  ngOnInit() {
    const code = this.activatedRoute.snapshot.queryParamMap.get('code');
    //console.log('Parâmetros ao chamar o componente gov-br: ');
    //console.log(JSON.stringify(this.activatedRoute.queryParams));
    this.auth.loginGovBr(code);
  }

}

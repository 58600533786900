




export const environment = {
  production: true,
  //OAuth2 WSO2
  clientId: 'DUicPf452dL5Iadrp58qRMmtPJca',
  redirectUri: 'https://dividaativa.ciasc.gov.br/DividaAtiva/dlogin',
  urlProvider: 'https://acesso.ciasc.sc.gov.br',
  urlApiGovBr: 'https://api.acesso.gov.br',
  urlServicosGovBr: 'https://sso.acesso.gov.br',

  URLPADRAOAPI: "https://dividaativa.ciasc.gov.br/divida-ativa-api/",
  URLPADRAO: "https://dividaativa.ciasc.gov.br/DividaAtiva/dlogin",
  //LOGIN SAU
  URLLOGIN: "https://sau.sc.gov.br/cas/login?service=",
  URLLOGOUT: "https://sau.sc.gov.br/cas/logout?service=",
  //ASSINADOR LOCAL
  URLASSINADOR: "http://localhost:9090/api/"

};

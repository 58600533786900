import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { map } from "rxjs/operators";

import { Uteis } from './../shared/Uteis';
import { AuthService } from '../shared/authentication/auth.service';
import { environment } from '../../environments/environment';
import { AppConfig } from '../app.config';

const URI = '/' + AppConfig.PAGINA_INICIAL;

@Component({
  selector: 'az-tela1',
  templateUrl: './tela1.component.html',
  styleUrls: ['./tela1.component.scss']
})
export class Tela1Component implements OnInit, AfterViewInit {
  public token: string;
  erroTOP: any;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    @Inject(DOCUMENT) private document: any) {
      Uteis.esconderOverlay();
  }

  ngOnInit() {
    //console.log(JSON.stringify(this.activatedRoute.queryParams));
    this.activatedRoute.queryParams.pipe(
      map(params => (params['ticket'] || params['code'] || 'None'))
    )
    .subscribe(
      ticket => {
        if (ticket === 'None') {
          let token: string = localStorage.getItem('token');
          if (token) {
            this.token = token;
          } else {
            this.token = null;
          }
          if (this.token != null) {
            this.router.navigate([URI]);
          }
        } else {
          //console.log('Tem ticket');
          //console.log(ticket);
          this.token = ticket;
          this.auth.login(ticket).subscribe(
            redirect => {
              this.router.navigate([redirect]);
            },
            erro => {
              const detalhesErro = Uteis.tratarErroHttp(erro);
              this.erroTOP = { status: detalhesErro.codigo, message: detalhesErro.mensagem };
              Uteis.esconderOverlay();
            }
          );
        }
      }
    );
  }

  ngAfterViewInit(): void {
    Uteis.esconderOverlay();
  }

  private urllimpa(url: string): string {
    let nomeApp = 'DividaAtiva';
    let i = url.indexOf('/' + nomeApp);
    return url.substring(0, i + nomeApp.length + 1);
  }

}

import { Uteis } from './../Uteis';
import { EntidadeBase } from '../EntidadeBase';
import { OrgaoDenatran } from '../../orgao-denatran/OrgaoDenatran';
import { RoleEnum } from './roles';
import { Autorizacao } from './Autorizacao';
import { JwtToken } from './JwtToken';

export class User extends EntidadeBase {
    id: number;
    username: string;
    cpf: string;
    orgaoDenatranAtual: OrgaoDenatran;
    orgaosDenatran: OrgaoDenatran[];
    roles: RoleEnum[];
    nome: string;

    get isActive(): boolean {
        return !this.hasRole(RoleEnum.DESATIVADO);
    }

    get isAdmin(): boolean {
        return this.hasRole(RoleEnum.ADMIN);
    }

    get isSuperAdmin(): boolean {
        return this.hasRole(RoleEnum.CIASC);
    }

    public static fromTokenData(tokenData: JwtToken): User {
        const user = new User();
        user.username = tokenData.username;
        user.roles = tokenData.roles;
        return user;
    }

    constructor(json?: any) {
        super(json);
        if (json) {
            this.orgaoDenatranAtual = new OrgaoDenatran(json.orgaoDenatranAtual);
            this.orgaosDenatran = OrgaoDenatran.toArray(json.orgaosDenatran);
            const autorizacoes = Autorizacao.toArray(json.autorizacoes);
            this.roles = autorizacoes.map(a => a.authority);
        }
    }

    public hasRole(role: RoleEnum) {
        if (this.roles) {
            for (let x = 0; x < this.roles.length; x++) {
                if (this.roles[x] === role) {
                    return true;
                }
            }
        }
        return false;
    }

    public hasAnyRole(roles: RoleEnum[]): boolean {
        return User.hasAnyRole(this.roles, roles);
    }

    public static hasAnyRole(userRoles: RoleEnum[], requiredRoles: RoleEnum[]): boolean {
        return userRoles.includes(RoleEnum.CIASC) || requiredRoles.some(r => userRoles.includes(r));
    }

    public toStringUser(): string {
        return this.nome + '<' + this.username + '>'
        + (this.roles ? (' (' + this.roles.map((r, index) => 'Role_' + index + ': ' + Uteis.stringFromRole(r) + ' ') +  ') ') : '')
        + (this.orgaoDenatranAtual ? ('de ' + this.orgaoDenatranAtual.nome) : '');
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { environment } from '../../environments/environment';

export abstract class ServicoRest {
    public static montarUrlRest(... params: any[]) {
        return environment.URLPADRAOAPI + params.join('/');
    }

    public static montarUrlRestAssinador(... params: any[]){
        return environment.URLASSINADOR + params.join('/');
    }

    constructor(@Inject(DOCUMENT) private document: any) { }

    protected montarUrlRest(... params: any[]) {
        return ServicoRest.montarUrlRest(... params);
    }

    protected montarUrlRestAssinador(... params: any[]) {
        return ServicoRest.montarUrlRestAssinador(... params);
    }

    protected montarQueryParams(params: any) {
        let url = '?';
        for (const key in params) {
            if (params[key] !== undefined) {
                url += `${key}=${params[key]}&`;
            }
        }
        return url;
    }

    protected handleErrorSendingFullError (error: HttpErrorResponse) {
        if (error.status === 401) {
            this.logout();
        }
        return throwError(error);
    }

    private logout(): void {
        localStorage.removeItem('token');
        this.document.location.href = environment.URLPADRAO;
    }
}

<div class="starter-template">
    <div *ngIf="!token" class="card-body widget-body form-horizontal">
    
        <div class="form-group col-md-12 row linha-botoes">
            <div class="bigtile encolhivel">&nbsp;</div>
            <div class="col-md-3 bigtile">
                <button routerLink="/login" class="btn botao-sau" type="button">
                    <div class="row">
                        <img src="assets/img/logo/logoSAU.png" alt="SAU" class="login sau"/>
                        <span class="bigcaps">SAU</span>
                    </div>
                    <span class="texto-botao">Login Estadual</span>
                </button>
            </div>
            <div class="col-md-3 bigtile">
                <button routerLink="/govbr" class="btn botao-govbr" type="button">
                    <img src="assets/img/logo/govbr.svg" alt="GovBr" class="login govbr"/>
                    <span class="texto-botao">Login Federal</span>
                </button>
            </div>
            <div class="bigtile encolhivel">&nbsp;</div>
        </div>

    </div>
</div>

import { RoleEnum } from './roles';
import { Uteis } from '../Uteis';

export class JwtToken {
    expireDate: Date;
    username: string;
    roles: Array<RoleEnum>;
    uid: number;

    private static urlBase64Decode(str: string) {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                // tslint:disable-next-line:no-string-throw
                throw 'Illegal base64url string!';
        }
        return decodeURIComponent((<any>window).escape(window.atob(output)));
    }

    public static getTokenData(token: string): JwtToken {
        const decodedToken = JwtToken.decodeToken(token);
        const tokenData = new JwtToken();
        tokenData.expireDate = new Date(decodedToken.exp * 1000);
        tokenData.username = decodedToken.sub;
        tokenData.roles = new Array<RoleEnum>();
        if (decodedToken.roles) {
            const rolesStr: string = decodedToken.roles;
            const splitRoles = rolesStr.split(',');
            splitRoles.forEach(r => tokenData.roles.push(Uteis.roleEnumFromString(r)));
        }
        tokenData.uid = decodedToken.uid;
        return tokenData;
    }

    public static decodeToken(token: string = '') {
        if (token === null || token === '') { return { 'upn': '' }; }
        const parts = token.split('.');
        if (parts.length !== 3) {

            throw new Error('JWT deve ter 3 partes');
        }
        const decoded = this.urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error('Não foi possível decodificar o token (' + token + ')');
        }
        return JSON.parse(decoded);
    }

    get isExpired(): boolean {
        const agora = new Date(Date.now());
        return this.expireDate < agora;
    }
}

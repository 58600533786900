import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { Erro } from './Erro';
import { RoleEnum } from './authentication/roles';

export class Uteis {
  
  public static readonly MASCARA_DATA_MMAAAA = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public static readonly MASCARA_DATA_DDMMAAAA = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public static readonly MASCARA_CDA_DEINFRA = [/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public static readonly MASCARA_CDA = [/\d/, /\d/, /\d/, /\d/,/\d/,/\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/,/\d/,/\d/, '-', /\d/, /\d/];
  public static readonly MASCARA_CPF = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  public static readonly MASCARA_CNPJ = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/',
    /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  /**
   * Remove todos acentos de palavras brasileiras em português
   * substituindo-os por letras sem acentos (sensível ao caso)
   * @param palavra 
   * @returns o mesmo string sem os acentos
   */
  public static removeAcentos(palavra: string): string {
    return palavra
      .replace(/[aáâàäã]/g, 'a').replace(/[AÁÂÀÄÃ]/g, 'A')
      .replace(/[eéêèë]/g, 'e').replace(/[EÉÊÈË]/g, 'E')
      .replace(/[iíîìï]/g, 'i').replace(/[IÍÎÌÏ]/g, 'I')
      .replace(/[oóôòöõ]/g, 'o').replace(/[OÓÔÒÖÕ]/g, 'O')
      .replace(/[uúûùü]/g, 'u').replace(/[UÚÛÙÜ]/g, 'U')
      .replace(/[cç]/g, 'c').replace(/[CÇ]/g, 'C');
  }

  public static isMail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  public static isDataBrasileira(data: string): boolean {
    const re = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    return re.test(String(data));
  }
  
  public static limparCpfCnpj(cpfCnpj: string): string {
    return cpfCnpj.replace(new RegExp('[-./]', 'g'), '');
  }

  public static formatarCpfCnpj(documento: string) {
    if (documento) {
      documento = documento.trim();
      if (documento.length === 11) {
        return documento.substr(0, 3) + '.' + documento.substr(3, 3) + '.' + documento.substr(6, 3) + '-' + documento.substr(9, 2);
      } else if (documento.length === 14) {
        return documento.substr(0, 2) + '.' + documento.substr(2, 3) + '.' + documento.substr(5, 3) + '/' + documento.substr(8, 4) +
          '-' + documento.substr(12, 2);
      } else {
        return documento;
      }
    } else {
      return '';
    }
  }

  public static verificaCPF(busca: string): boolean {
    let ehCPF = false;
    let regexCPF = /^\d{3}[\.|-]?\d{3}[\.|-]?\d{3}[\.|-]?\d{2}$/;
    if (regexCPF.test(busca)) { // passa no 1º teste
      let cpf = busca.replace(/\D/g, '');
      let soma: number = 0;
      soma += parseInt(cpf[0]) * 10;
      soma += parseInt(cpf[1]) * 9;
      soma += parseInt(cpf[2]) * 8;
      soma += parseInt(cpf[3]) * 7;
      soma += parseInt(cpf[4]) * 6;
      soma += parseInt(cpf[5]) * 5;
      soma += parseInt(cpf[6]) * 4;
      soma += parseInt(cpf[7]) * 3;
      soma += parseInt(cpf[8]) * 2;
      soma = (soma * 10) % 11;
      if (soma === 10 || soma === 11) {
        soma = 0;
      }
      ehCPF = (soma == parseInt(cpf[9])); //digito1
      soma = 0;
      soma += parseInt(cpf[0]) * 11;
      soma += parseInt(cpf[1]) * 10;
      soma += parseInt(cpf[2]) * 9;
      soma += parseInt(cpf[3]) * 8;
      soma += parseInt(cpf[4]) * 7;
      soma += parseInt(cpf[5]) * 6;
      soma += parseInt(cpf[6]) * 5;
      soma += parseInt(cpf[7]) * 4;
      soma += parseInt(cpf[8]) * 3;
      soma += parseInt(cpf[9]) * 2;
      soma = (soma * 10) % 11;
      if (soma === 10 || soma === 11) {
        soma = 0;
      }
      return (ehCPF ? (soma === parseInt(cpf[10])) : false); //digito2
    }
    return false;
  }

  /**
   * !!!Atenção!!! UPDATE Outubro.2022 -> Usar:
   * " | currency : 'BRL' " ao invés desse método!
   * O número tem que chegar aqui #######.##
   * @param valor c/ 2 casas depois do ponto
   * @returns #.###.###,##
   */
  public static formatarMoeda(valor: Number) {
    if (valor == undefined) { return ''; }
    if (valor == null) { return ''; }
    if (valor == Infinity) { return ''; }
    if (valor < 0) { return ''; } // zero = 0.00
    let n = valor.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    if (valor < 1000) {
      return valor.toFixed(2).replace(/\./g,',');
    }
    let numero = n.split('.');
    numero[0] = numero[0].replace(/\./g,'');
    let l = numero[0].length;
    numero[0] = "R$ " + (l > 6) ? (numero[0].split(/(?=(?:...)*$)/).join('.')) : numero[0];
    return numero.join(',');
  }

  public static formatarData(data: Date) {
    return moment(data).format('DD/MM/YYYY');
  }

  public static formataDataParaServidor(date: string) {
    if (!date) {
      return null;
    }
    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }

  public static formataMesExtenso(numeroDoMes: number): string {
    if (isNaN(numeroDoMes) || numeroDoMes == null) { return null; }
    if (numeroDoMes < 0 || numeroDoMes > 11) { return null; }
    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    return meses[numeroDoMes];
  }

  public static roleEnumFromString(str: string): RoleEnum {
    switch (str) {
      case "ROLE_ADMIN":
      case "Administrador":
      case "administrador":
      case "Admin":
      case "admin":
        return RoleEnum.ADMIN;
      case "ROLE_OPERADOR":
      case "Operador":
      case "operador":
        return RoleEnum.OPERADOR;
      case "ROLE_CONSULTA":
      case "Consulta":
      case "consulta":
        return RoleEnum.CONSULTA;
      case "ROLE_REPORT":
      case "Relatório":
      case "relatório":
      case "Relatorio":
      case "relatorio":
        return RoleEnum.REPORT;
      case "ROLE_DESATIVADO":
      case "Desativado":
      case "desativado":
        return RoleEnum.DESATIVADO;
      case "ROLE_SERVICO":
      case "Serviço":
      case "serviço":
      case "Servico":
      case "servico":
        return RoleEnum.SERVICO;
      case "ROLE_CIASC":
      case "Super Admin":
      case "super admin":
      case "SuperAdmin":
      case "superadmin":
      case "Super admin":
      case "super Admin":
      case "superAdmin":
        return RoleEnum.CIASC;
      case "ROLE_BETA":
      case "Beta":
      case "beta":
        return RoleEnum.BETA;
      default:
        return RoleEnum.DESATIVADO;
    }
  }

  public static stringFromRole(r: RoleEnum): string {
    switch (r) {
      case RoleEnum.ADMIN:
        return 'Administrador';
      case RoleEnum.OPERADOR:
        return 'Operador';
      case RoleEnum.CONSULTA:
        return 'Consulta';
      case RoleEnum.REPORT:
        return 'Relatórios';
      case RoleEnum.DESATIVADO:
        return 'Desativado';
      case RoleEnum.SERVICO:
        return 'Serviço';
      case RoleEnum.CIASC:
        return 'Super Admin';
      case RoleEnum.BETA:
        return 'Beta';
      default:
        return 'Desativado';
    }
  }

  public static buscarMascaraCpfCnpj(input): any[] {
    const numbers = input.match(/\d/g);
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
    }

    if (numberLength <= 11) {
      return this.MASCARA_CPF;
    } else {
      return this.MASCARA_CNPJ;
    }
  }

  public static buscarMascaraCda(input): any[] {
    const numbers = input.match(/\d/g);
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.join('').length;
    }

    if (numberLength <= 11) {
      return this.MASCARA_CDA_DEINFRA;
    } else {
      return this.MASCARA_CDA;
    }
  }

  public static formatError(er) {
    return {
      status : er.status,
      message : Array.isArray(er.error) ? er.error.map(erro => erro.mensagemUsuario).join('<br/>') : er.error.mensagemUsuario
    }
  }

  public static tratarErroHttp(httpResponse: HttpErrorResponse): Erro {
    return Erro.tratarErroHttp(httpResponse);
  }

  public static mostrarOverlay() {
    document.getElementById('preloader').style['display'] = 'block';
  }

  public static esconderOverlay() {
    document.getElementById('preloader').style['display'] = 'none';
  }

  //************ */
  public static verificaCNPJ(cnpj) {
    // Verifica se a variável cnpj é igua a "undefined", exibindo uma msg de erro
    if (cnpj === undefined) {
      return false;
    }

    // Esta função retira os caracteres . / - da string do cnpj, deixando apenas os números 
    var strCNPJ = cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');

    // Testa as sequencias que possuem todos os dígitos iguais e se o cnpj não tem 14 dígitos, retonando falso e exibindo uma msg de erro
    if (strCNPJ === '00000000000000' || strCNPJ === '11111111111111' || strCNPJ === '22222222222222' || strCNPJ === '33333333333333' ||
      strCNPJ === '44444444444444' || strCNPJ === '55555555555555' || strCNPJ === '66666666666666' || strCNPJ === '77777777777777' ||
      strCNPJ === '88888888888888' || strCNPJ === '99999999999999' || strCNPJ.length !== 14) {
      
      return false;
    }

    // A variável numeros pega o bloco com os números sem o DV, a variavel digitos pega apenas os dois ultimos numeros (Digito Verificador).
    var tamanho = strCNPJ.length - 2;
    var numeros = strCNPJ.substring(0, tamanho);
    var digitos = strCNPJ.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    // Os quatro blocos seguintes de funções irá reaizar a validação do CNPJ propriamente dito, conferindo se o DV bate. Caso alguma das funções não consiga verificar
    // o DV corretamente, mostrará uma mensagem de erro ao usuário e retornará falso, para que o usário posso digitar novamente um número 
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = strCNPJ.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let k = tamanho; k >= 1; k--) {
      soma += numeros.charAt(tamanho - k) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) {
      return false;
    }

    return true;
  }
  //*********** */
}

import { NgModule } from "@angular/core";
import { Modal3Component } from "./modal3.component";
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    NgbModule,
    CommonModule
  ],

  declarations: [Modal3Component],

  providers: [],

  entryComponents: [Modal3Component]
})
export class Modal3Module {}

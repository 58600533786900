export enum RoleEnum {
  ADMIN = <any>"ROLE_ADMIN",
  CONSULTA = <any>"ROLE_CONSULTA",
  OPERADOR = <any>"ROLE_OPERADOR",
  DESATIVADO = <any>"ROLE_DESATIVADO",
  REPORT = <any>"ROLE_REPORT",
  SERVICO = <any>"ROLE_SERVICO",
  CIASC = <any>"ROLE_CIASC",
  BETA = <any>"ROLE_BETA",

  //Se adicionar algum role aqui alterar em Uteis.roleEnumFromString e Uteis.stringFromRole também
}

import { EntidadeBase } from "../shared/EntidadeBase";

export class OrgaoDenatran extends EntidadeBase {
    public id: number;
    public nome: string;
    public cnpj:string = '';
    public endereco:string = '';
    public cidade:string = '';
    public numeroContrato: string = '';
    public numeroCartaoPostagem: string = '';
    public blumenau: boolean;

    static readonly ID_DEINFRA = 125200;

    static toArray(jsons : any[]) : OrgaoDenatran[] { // TODO: Tentar mover esse tipo de método para a classe base
        let objetos : any[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                objetos.push(new OrgaoDenatran(json));
            }
        }
        return objetos;
    }
}

import { Observable } from 'rxjs';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { Uteis } from '../Uteis';
import { User } from '../authentication/user';
import { Filtros } from '../../usuarios/filtros';
import { Autorizacao } from "../authentication/Autorizacao";
import { RoleEnum } from "../authentication/roles";
import { OrgaoDenatran } from "../../orgao-denatran/OrgaoDenatran";
import { UsuariosService } from "../../usuarios/usuarios.service";

interface UsuarioDTO {
  id: number;
  username: string;
  cpf: string;
  autorizacoes: Autorizacao[];
  orgaoDenatranAtual: OrgaoDenatran;
}

interface EntradaUserHandle {
  handle: string;
  user: User;
}

@Component({
  selector: "ngbd-modal",
  templateUrl: "./modal3.component.html"
})
export class Modal3Component {

  @ViewChild('focar', { static: false }) public buttonPraFocar: ElementRef;
  @ViewChild('ok', { static: false }) public buttonOkPraFocar: ElementRef;
  @Input() public title: string;
  @Input() public message: string;
  @Input() public handle: string;

  constructor(public activeModal: NgbActiveModal) {}

  passBack(saida: string) {
    this.activeModal.close(saida);
  }

}
import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';

import { CryptoService } from './../shared/authentication/crypto.service';

import { GovBrComponent } from './gov-br.component';
import { GovBrRoutingModule } from './gov-br-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    GovBrRoutingModule
  ],
  declarations: [
    GovBrComponent
  ],
  providers: [
    CryptoService
  ]
})
export class GovBrModule {}
import { HttpErrorResponse } from '@angular/common/http';

export class Erro {
  mensagem: string;
  codigo: number;
  timeout?: boolean;

  public static tratarErroHttp(httpResponse: HttpErrorResponse): Erro {
    console.error(httpResponse);
    const erro = new Erro("");
    erro.codigo = httpResponse.status;
    if (httpResponse.status === 409) {
      erro.mensagem = "Auto já importado";
    } else if (httpResponse.status === 400) {
      if (
        httpResponse.error instanceof ArrayBuffer &&
        "TextDecoder" in window
      ) {
        var decoder = new TextDecoder("utf-8");
        var jsonError = JSON.parse(decoder.decode(httpResponse.error));
        console.error("error:", jsonError);
        erro.mensagem = jsonError
          .map((obj) => obj.mensagemUsuario)
          .join("<br/>");
      } else {
        erro.mensagem = httpResponse.error
          .map((obj) => obj.mensagemUsuario)
          .join("<br/>");
      }
    } else if (httpResponse.status === 401) {
      erro.mensagem =
        "Sessão expirada ou sem permissão de acesso. Favor efetuar login novamente";
    } else if (
      (httpResponse.name as any) === "TimeoutError" ||
      httpResponse.status === 503
    ) {
      erro.mensagem = "O serviço demorou demais para responder";
      erro.timeout = true;
    } else if (httpResponse.error) {
      erro.mensagem = httpResponse.error.message;
      if (!erro.mensagem && httpResponse.error.length) {
        erro.mensagem = httpResponse.error
          .map((obj) => obj.mensagemUsuario)
          .join("<br/>");
      }
    } else if (httpResponse.status >= 500) {
      erro.mensagem = "Erro interno do servidor";
    } else {
      erro.mensagem = httpResponse.statusText || httpResponse.message;
    }
    return erro;
  }

  public static pegaMensagemDesenvolvedor(httpResponse: HttpErrorResponse): string {
    return (httpResponse.error || []).map((obj) => obj.mensagemDesenvolvedor).join(',');
  }

  constructor(mensagem: string) {
    this.mensagem = mensagem;
    this.codigo = null;
  }
}

import { Injectable } from '@angular/core';
import CryptoES from 'crypto-es';

@Injectable({
	providedIn: 'root'
})
export class CryptoService {
	
	constructor() {}
	
	public generateCodeVerifier() {
		var code_verifier = this.generateRandomString(128);
		return code_verifier;
	}
	
	private generateRandomString(length: Number) {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (var i = 0; i < length; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}
	
	public generateCodeChallenge(code_verifier: string) {
		var code_challenge = this.base64URL(CryptoES.SHA256(code_verifier));
		return code_challenge;
	}
	
	private base64URL(value: CryptoES.lib.WordArray) {
		return value.toString(CryptoES.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
	}
	
}

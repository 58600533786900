import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GovBrComponent } from './gov-br.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'govbr', component: GovBrComponent }
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class GovBrRoutingModule { }

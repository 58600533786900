<div class="container">
    <div class="row">
         <div class="col-xl-4 col-md-6 col-10 mx-auto p-t-10">             
             <div class="card">
                <div class="card-body text-center">
                    <h1 class="display-1">404</h1>
                    <h6 class="card-title">Opps, parece que essa página não existe.</h6>

                    <a class="navbar-brand" routerLink="/pages/autos/dash">
                        <img src="assets/img/logo/logo_divida_ativa.png" alt="" class="d-inline" style="width: 50px"> 
                    </a>
                    <div>
                    </div>

                </div>
             </div>
         </div>
    </div>
 </div>

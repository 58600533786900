import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { map } from "rxjs/operators";

import { environment } from '../../environments/environment';
import { AuthService } from '../shared/authentication/auth.service';
import { Uteis } from '../shared/Uteis';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  token: String;
  erroTOP: any;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    @Inject(DOCUMENT) private document: any) {
      Uteis.mostrarOverlay();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(
      map(params => params['ticket'] || 'None')
    )
    .subscribe(
      ticket => {
        if (ticket === 'None') {
          this.document.location.href = environment.URLLOGIN + environment.URLPADRAO;
        } else {
          this.auth.login(ticket).subscribe(
            redirect => {
              this.router.navigate([redirect]);
            },
            erro => {
              const detalhesErro = Uteis.tratarErroHttp(erro);
              this.erroTOP = { status: detalhesErro.codigo, message: detalhesErro.mensagem };
              Uteis.esconderOverlay();
            }
          );
        }
      }
    );
  }
}

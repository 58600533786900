
import { of as observableOf,  Observable, throwError, BehaviorSubject, pipe } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError, flatMap, share } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { User } from '../../shared/authentication/user';
import { HttpClient, HttpBackend, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AppConfig } from '../../app.config';
import { OrgaoDenatran } from '../../orgao-denatran/OrgaoDenatran';
import { ServicoRest } from '../ServicoRest';
import { TokenResponse } from './tokenResponse';
import { JwtToken } from './JwtToken';
import { RoleEnum } from './roles';
import { CryptoService } from './crypto.service';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Modal3Component } from '../modal3/modal3.component';

const clientId = environment.clientId;
const redirectUri = environment.redirectUri;
const urlProvider = environment.urlProvider;
const urlApiGovBr = environment.urlApiGovBr;
const urlServicosGovBr = environment.urlServicosGovBr;
const URI = '/' + AppConfig.PAGINA_INICIAL;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
    private static readonly WHO_REQUEST_COOLDOWN = 2000;
    private lastWhoRequestTime = 0;
    redirectUrl: string;
    private _currentUser: User = null;
    public httpToken: HttpClient;
    private whoObservable: Observable<User>;
    private access_tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public access_tokenSubject$: Observable<string> = this.access_tokenSubject.asObservable();
    public backend_jwt = {};
    public id_token = '';
    public mostrar_foto = false;
    public empresas = [];
    public api_data = { principal: null };
    public erroTOP: { status: number; message: string };
    public erroGovBrMensagemDesenvolvedor = 'Usuário não possui acesso pelo gov.br';
    public tipoDeTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public tipoDeToken$: Observable<string> = this.tipoDeTokenSubject.asObservable();
    public mensagemModal: string;
    private optionsModal: NgbModalOptions = {
        backdrop: true, //'static',
        centered: true,
        backdropClass: 'backdrop-modal',
        windowClass: 'position-modal',
        keyboard: true,
        ariaLabelledBy: 'modal-basic-title'
    };

    constructor(
        private handler: HttpBackend,
        private crypto: CryptoService,
        private http: HttpClient,
        public router: Router,
        private modalService: NgbModal,
        @Inject(DOCUMENT) private document: any
    ) {
        this.httpToken = new HttpClient(handler);
        this.mensagemModal =
            `	Seu cadastro não está habilitado para acesso ao
            sistema utilizando o GovBr.
            	Entre em contato com o Responsável pelo Órgão,
            para efetuar a atualização do CPF no seu cadastro.`;
    }

    get currentUser(): User {
        if (this._currentUser) {
            return this._currentUser;
        } else if (this.tokenData) {
            return User.fromTokenData(this.tokenData);
        } else {
            return null;
        }
    }

    get token(): string {
        return localStorage.getItem('token');
    }

    /**
    * É lido lá em navbar.component pra mostrar
    * em qual login o usuário está logado.
    */
    get tipoDeLogin(): string {
        return localStorage.getItem('tipoDeLogin');
    }

    get tokenData(): JwtToken {
        return this.token ? JwtToken.getTokenData(this.token) : null;
    }

    /**
    * Subscreve no behaviorSubject do tipoDeToken
    * pra alterar o tipo de token no momento que
    * o usuário faz o login e guarda o tipo de login
    * escolhido no localStorage.
    * @param ticket
    */
    public SAUOuGovBr(ticket: string): void {
        const SAU = '-cas'; // O ticket do SAU termina com '-cas'
        let tipo = ticket.slice(-4);
        this.tipoDeToken$.subscribe((tipoAtual) => {
            if (tipo === SAU) {
                if (tipoAtual !== 'sau') {
                    this.tipoDeTokenSubject.next('sau');
                }
            } else {
                if (tipoAtual !== 'govbr') {
                    this.tipoDeTokenSubject.next('govbr');
                }
            }
        });
        localStorage.setItem('tipoDeLogin', this.tipoDeToken());
    }

    /**
    * Subscreve no behaviorSubject do tipoDeToken
    * pra ler o tipo de login escolhido pelo usuário
    * @returns
    */
    public tipoDeToken(): string {
        let ret: string = '';
        this.tipoDeToken$.subscribe((tipo) => {
            if (tipo) {
                ret = tipo;
            }
        });
        return ret;
    }

    get permissaoEdicao(): boolean {
        return this.hasRole(RoleEnum.ADMIN) || this.hasRole(RoleEnum.OPERADOR);
    }

    /**
    * Método que verifica se o usuário está autenticado no SAU ou no Gov.br.
    * @returns se está autenticado ou não no SAU/Gov.br
    */
    isLoggedIn(): boolean {
        if (this.token) {
            const tokenData = this.tokenData;
            if (tokenData.isExpired) {
                console.warn('Token expirado (Validade: ' + tokenData.expireDate + ' - Hora: ' + new Date(Date.now()) + ')');
            } else if (!User.fromTokenData(tokenData).hasRole(RoleEnum.DESATIVADO)) {
                return true;
            }
        }

        this.logout();
        return false;
    }

    orgaoDenatranAtual(): Observable<OrgaoDenatran> {
        if (this.currentUser && this.currentUser.orgaoDenatranAtual) {
            return observableOf(this.currentUser.orgaoDenatranAtual);
        }
        return this.who().pipe(map((user) => user.orgaoDenatranAtual));
    }

    /**
    * Implementado o login duplo. Aqui o sistema identifica de quem é o ticket e faz o login.
    * @param ticket
    * @returns
    */
    login(ticket: string): Observable<string> {
        this.SAUOuGovBr(ticket);
        if (this.tipoDeToken() === 'sau') {
            return this.validarTicket(ticket).pipe(
                map((tokenResponse) => {
                    localStorage.setItem('token', tokenResponse.token);
                    const redirect = this.redirectUrl ? this.redirectUrl : URI;
                    return redirect;
                }),
                catchError(this.handleErrorSendingFullError)
            );
        }
        if (this.tipoDeToken() === 'govbr') {
            //console.log('validarTicketGovbr no método login');
            //console.log('code: ', ticket);
            return this.loginGovBr(ticket).pipe(
                flatMap((data) => {
                    this.id_token = data.id_token;
                    return this.pegaTokenDividaAtiva(data.access_token);
                })
            );
        }
        if (this.isLoggedIn()) {
            return observableOf(URI);
        }
    }

    private pegaTokenDividaAtiva(t): Observable<string> {
        if (t) {
            return this.httpToken.post<TokenResponse>(environment.URLPADRAOAPI + 'govbr/login', { token: t })
                .pipe(
                    map((tokenResponse: TokenResponse) => {
                        this.backend_jwt = this.parseJwt(tokenResponse.token);
                        //console.log('this.backend_jwt', JSON.stringify(this.backend_jwt));
                        localStorage.setItem('token', tokenResponse.token);
                        //console.log('Entrou no método login e pegou este token no backend para Govbr: ' + tokenResponse.token);
                        const redirect = this.redirectUrl ? this.redirectUrl : URI;
                        return redirect;
                    }),
                    catchError(this.handleErrorSendingFullError.bind(this))
                );
        } else {
            return observableOf('');
        }
    }

    logout(): void {
        localStorage.removeItem('token');
        this.document.location.href = environment.URLLOGOUT + environment.URLPADRAO;
    }

    //======== Método de autenticação do SAU ==========================
    private validarTicket(ticket: string): Observable<TokenResponse> {
        let params = new HttpParams();
        params = params.append('token', ticket);
        params = params.append('service', environment.URLPADRAO);
        return this.httpToken.get<TokenResponse>(environment.URLPADRAOAPI + 'validate', { params: params });
    }
    //======== Fim dos métodos de autenticação do SAU ===============================

    //======== Início dos métodos de autenticação do Gov.br =========================
    public loginGovBr(code: string): Observable<any> {
        if (code) {
            var code_verifier = localStorage.getItem('app-code-verifier');
            localStorage.removeItem('app-code-verifier');

            const body = new HttpParams()
                .set('grant_type', 'authorization_code')
                .set('client_id', clientId)
                .set('redirect_uri', redirectUri)
                .set('code', code)
                .set('code_verifier', code_verifier);

            //console.log('parâmetros: ');
            //console.log(body.toString());

            return this.http.post<any>(
                urlProvider + '/oauth2/token',
                body.toString(),
                { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })}
            );
        } else {
            var code_verifier = this.crypto.generateCodeVerifier();

            localStorage.setItem('app-code-verifier', code_verifier);
            var code_challenge = this.crypto.generateCodeChallenge(code_verifier);

            const url = urlProvider + '/oauth2/authorize' +
                '?response_type=code' +
                '&client_id=' + clientId +
                '&redirect_uri=' + redirectUri +
                '&code_challenge=' + code_challenge +
                '&code_challenge_method=S256&scope=openid+email+phone+govbr_empresa';

            this.document.location.href = url;
        }
    }

    public logoff() {
        const url = urlProvider + '/oidc/logout';

        var form = document.createElement('form');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', url);

        var field1 = document.createElement('input');
        field1.setAttribute('type', 'hidden');
        field1.setAttribute('name', 'id_token_hint');
        field1.setAttribute('value', this.backend_jwt['id_token_govbr']);
        form.appendChild(field1);

        var field2 = document.createElement('input');
        field2.setAttribute('type', 'hidden');
        field2.setAttribute('name', 'post_logout_redirect_uri');
        field2.setAttribute('value', redirectUri);
        form.appendChild(field2);

        var field3 = document.createElement('input');
        field3.setAttribute('type', 'hidden');
        field3.setAttribute('name', 'state');
        field3.setAttribute('value', 'state-logout');
        form.appendChild(field3);

        document.body.appendChild(form);

        form.submit();
    }

    public logoffGovBr() {
        this.access_tokenSubject.next('');
        const url = urlServicosGovBr + '/logout?post_logout_redirect_uri=' + redirectUri;

        var form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', url);
        document.body.appendChild(form);

        form.submit();
    }

    public getPhoto() {
        if (this.access_tokenSubject.value > '' && this.backend_jwt['picture']) {
        // desabilitar CORS no browser (usando plugin)
            this.http.get<Blob>(
                this.backend_jwt['picture'],
                {
                    responseType: 'blob' as 'json',
                    headers: new HttpHeaders()
                        .set('Accept', 'application/json')
                        .set('Authorization', 'Bearer ' + this.backend_jwt['access_token_govbr'])
                }
            ).subscribe((data) => {
                this.mostrar_foto = true;

                var reader = new FileReader();
                reader.onloadend = function () {
                    var base64data = reader.result;
                    var img = document.getElementById('imgFoto');
                    img['src'] = base64data;
                };
                reader.readAsDataURL(data);
            });
        }
    }

    public getEmpresas() {
        if (this.access_tokenSubject.value > '' && this.backend_jwt['cpf']) {
        // desabilitar CORS no browser (usando plugin)
            const urlApi = urlApiGovBr + '/empresas/v2/empresas?filtrar-por-participante=' + this.backend_jwt['cpf'];
            this.http.get<any>(
                urlApi,
                { headers: new HttpHeaders({
                    Authorization: 'Bearer ' + this.backend_jwt['access_token_govbr']
                })}
            ).subscribe((data) => {
                console.log('Retorno API', data);
                this.api_data = data;
                },
                (error) => {
                    console.log('error', error);
                }
            );
        }
    }

    public consomeAPI() {
        var urlApi = 'https://apim.ciasc.sc.gov.br:8243/wso2ref-api/1.0-0/user';
        this.http.get<any>(
            urlApi,
            { headers: new HttpHeaders({
                Authorization: 'Bearer ' + this.access_tokenSubject.value
            })}
        ).subscribe((data) => {
            console.log('Retorno API', data);
            this.api_data = data;
            },
            (error) => {
                console.log('error', error);
            }
        );
    }

    public parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    public get name() {
        return this.backend_jwt['name'];
    }

    public get sub() {
        return this.backend_jwt['sub'];
    }

    public get exp() {
        return this.backend_jwt['exp'];
    }

    public get cpf() {
        return this.backend_jwt['cpf'];
    }

    public get email() {
        return this.backend_jwt['email'];
    }

    public get apiData() {
        return this.api_data;
    }

    public get emailVerified() {
        return this.backend_jwt['email_verified'];
    }

    public get accessTokenGovBR() {
        return this.backend_jwt['access_token_govbr'];
    }

    public get idTokenGovBR() {
        return this.backend_jwt['id_token_govbr'];
    }

    public showPhoto() {
        return this.mostrar_foto;
    }

    public get logoutLink() {
        const urlLogoutLink = urlProvider +
            '/oidc/logout?id_token_hint=' + this.id_token +
            '&post_logout_redirect_uri=' + redirectUri;
        return urlLogoutLink;
    }
    //======== Fim dos métodos de autenticação do Gov.br ===========================

    hasRole(role) {
        this.who();
        return (
            this.currentUser &&
            this.currentUser.isActive &&
            this.currentUser.hasRole(role)
        );
    }

    hasAnyRole(roles: RoleEnum[]): boolean {
        if (this.tokenData.isExpired) {
            return false;
        }

        return this.currentUser.hasAnyRole(roles);
    }

    trocarOrgao(orgao: OrgaoDenatran): Observable<User> {
        return this.http.post<User>(ServicoRest.montarUrlRest('trocar-orgao'), orgao.id)
        .pipe(
            map((resposta) => {
                this._currentUser = new User(resposta);
                return this.currentUser;
            }),
            catchError(this.handleErrorSendingFullError)
        );
    }

    public who(): Observable<User> {
    // Implementado um 'cooldown' para os requests em /me, pois em alguns casos o sistema
    // disparava dezenas de requisições deste tipo por segundo, praticamente travando o navegador
        if ( Date.now() - this.lastWhoRequestTime > AuthService.WHO_REQUEST_COOLDOWN ) {
            this.lastWhoRequestTime = Date.now();
            this.whoObservable = this.http.get<User>(ServicoRest.montarUrlRest('me'))
                .pipe(share())
                .pipe(
                    map((response) => {
                        this._currentUser = new User(response);
                        return this.currentUser;
                    }),
                    catchError(this.handleErrorSendingFullError)
                );
        }
        if (this.whoObservable) {
            return this.whoObservable;
        }
        return new Observable<User>();
    }

    private handleErrorSendingFullError(error: HttpErrorResponse) {
        const errMsg = error.message ? error.message : 'Server error';
        const erros: any[] = error.error;
        console.error(errMsg);
        if (error.status === 400) {
            // Não tem usuário nesse ponto.
            // Procura especificamente pelo erro de Autenticação GovBr
            for (let index = 0; index < erros.length; index++) {
                if (erros[index].mensagemDesenvolvedor) {
                    const erroAuth: string = erros[index].mensagemDesenvolvedor;
                    if (erroAuth.indexOf('AutenticacaoException') > -1) {
                        if (erroAuth.indexOf(this.erroGovBrMensagemDesenvolvedor) > -1) {
                            this.open('ATENÇÃO USUÁRIO!', this.mensagemModal, 'ok', this.optionsModal);
                        }
                    }
                }
            }
        }
        if (error.status === 401) {
            this.logout();
        }
        return throwError(error);
    }

    /**
     * Abre Modal3Component
     * Última orientação: 27/10/22 "põe todos os retornos em logout();"
     * @param title Título do Modal
     * @param message Conteúdo do Modal
     * @param handle 'ok' p/ botão ok, ou outros p/ botão fechar.
     * @param options configurações de posição e backdrop
     */
    public open(title: string, message: string, handle: string, options: NgbModalOptions) {
        const modalRef: NgbModalRef = this.modalService.open(Modal3Component, options);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.handle = handle;
        if (handle.toLowerCase() === 'ok' ) {
            modalRef.componentInstance.message = message;
        } else {
            modalRef.componentInstance.message = null;
        }
        modalRef.result.then((result) => {
            if (result == 'ok') {
                this.logout();
            }
            console.log('Modal fechado ' + result);
        }, (reason) => { // Não foi confirmado (promise reject)
            console.log('Modal fechado ' + this.getDismissReason(reason));
            if (reason == 'clicando em Cancelar') {
                this.logout();
            } else {
                this.logout(); // Botar tudo em logout();
            }
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'clicando ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'clicando fora';
        } else {
            return `${reason}`;
        }
    }
}

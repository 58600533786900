export abstract class EntidadeBase {
  constructor(json?: any) {
    if (json) {
      const chaves = Object.keys(json);
      for (let i = 0; i < chaves.length; i++) {
        const str: string = json[chaves[i]];
        // Regex para verificar se é uma data válida
        if (str && typeof str === 'string' && str.match('[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z')) {
          this[chaves[i]] = new Date(str);
        } else {
          this[chaves[i]] = json[chaves[i]];
        }
      }
    }
  }

  // TODO: Testar se isso resolve a criação de um toArray genérico
  /* toArray<T>(jsons: Iterable<T>): T[] {
    return [...jsons];
  } */
 
}
